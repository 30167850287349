.widget-g-twin {
  display: flex;
  flex-wrap: wrap;
  margin: 6rem 0;

  .card {
    display: flex;
    flex-direction: column;
    align-items: center;
  }

  .img-wrapper {
    width: 80%;
    margin: 0 auto;
    overflow: hidden;
    min-height: 12rem;
    transition: max-height 0.3s linear;

    img {
      width: 100%;
    }
  }

  .text-wrapper {
    width: 80%;
    margin: 0 auto;
    padding: 2.4rem;
    text-align: center;

    .title {
      font-size: 3.2rem;
      font-weight: 300;

      line-height: 1;
    }

    .subtitle {
      font-size: 1.4rem;

      color: $dark_grey;
      line-height: 1;
      margin-top: 1.5rem;
    }

    .item-cta {
      display: block;
      width: fit-content;
      margin: 2.4rem auto 0 auto;
      text-decoration: none;
    }
  }
}