.widget-g-twin {
  display: flex;
  margin: 3rem 0;

  .card {
    margin-bottom: 2rem;
  }
  .img-wrapper {
    width: 100%;
  }
  .text-wrapper {
    .title {
      font-size: 2.8rem;
    }
  }
}
