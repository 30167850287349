.widget-h-product-focus {
  display: grid;
  grid-template-columns: 1fr 1fr;
  grid-column-gap: 2.4rem;

  .product-focus-component {
    .product-id {
      &__wrapper .content-items {
        width: calc(100% - 2rem);
        max-width: 100%;
      }
    }
  }
}