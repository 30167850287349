.widget-a {
  margin-bottom: 2.4rem;

  &-title {
    font-size: 2.8rem;
  }

  &.margin-l {
    margin-bottom: 6rem;
  }
}