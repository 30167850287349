.widget-m-media-quote {
  img {
    width: 100%;
    height: auto;
    margin-top: 4rem;
    margin-bottom: 4rem;
  }

  .text {
    margin: 4rem auto 12rem auto;
    font-size: 5.4rem;
    font-weight: 300;
    line-height: 1.15;

  }
}