.listing-container {
  .widget-plp {
    grid-column: span 6;
  }

  .plp-full-width {
    @each $breakpoint, $metrics in $grid-metrics {
      @if $breakpoint== 'xs' {
        transform: translateX(-#{map-get($metrics, 'container-padding')});
        width: calc(100% + #{map-get($metrics, 'container-padding')} * 2);
      } @else {
        @include breakpoint($breakpoint, min) {
          transform: translateX(-#{map-get($metrics, 'container-padding')});
          width: calc(100% + #{map-get($metrics, 'container-padding')} * 2);
        }
      }
    }
  }
}
