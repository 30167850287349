.widget-h-product-focus {
  display: flex;
  margin: 6rem 0;

  &.row-reverse {
    flex-direction: row-reverse;
  }

  .product-focus-image {
    &--wrapper {
      margin: 0 auto;

      &.small {
        margin: auto auto;
      }
    }
  }

  .product-focus-component {
    position: relative;

    .img-wrapper {
      img {
        width: 100%;
      }
    }

    &.open {
      .product-id__item {
        &:not(:first-of-type) {
          display: flex;
        }
      }
    }

    .product-id {
      &__wrapper .content-items {
        display: flex;
        flex-direction: column-reverse;
        position: absolute;
        bottom: 1rem;
        right: 1rem;
        max-width: 30rem;
        width: -webkit-fill-available;
        padding: 0.8rem;
        @include rounded-box;
      }

      &__item {
        display: flex;

        &:not(:first-of-type) {
          margin-bottom: 0.8rem;
          display: none;
        }

        img {
          width: 4.8rem;
          height: 6.4rem;
          object-fit: cover;
        }

        .text-wrapper {
          flex: 1;
          margin: auto 0 auto 0.8rem;
          font-size: 1.4rem;
          line-height: 1.29;
        }
      }
    }

    //Button plus
    .btn-product-slider {
      width: 1.6rem;
      height: 1.6rem;
      position: absolute;
      bottom: 1.6rem;
      right: 0.8rem;

      transform: translateX(-50%);
      display: flex;
      justify-content: center;
      align-items: center;
    }

    .closed {
      .vertical {

        transition: all 0.3s ease-in-out;
        transform: rotate(-90deg);
      }

      .horizontal {

        transition: all 0.3s ease-in-out;
        transform: rotate(-90deg);
        opacity: 1;
      }
    }

    .opened {
      opacity: 1;

      .vertical {

        transition: all 0.3s ease-in-out;
        transform: rotate(90deg);
      }

      .horizontal {

        transition: all 0.3s ease-in-out;
        transform: rotate(90deg);
        opacity: 0;
      }
    }

    .btn-product-slider .circle {
      position: relative;
    }

    .btn-product-slider .circle .horizontal {
      position: absolute;
      background-color: $red;
      width: 1.6rem;
      height: 0.2rem;
      left: 50%;
      margin-left: -0.8rem;
      top: 50%;
      margin-top: -0.1rem;
    }

    .btn-product-slider .circle .vertical {
      position: absolute;
      background-color: $red;
      width: 0.2rem;
      height: 1.6rem;
      left: 50%;
      margin-left: -0.1rem;
      top: 50%;
      margin-top: -0.8rem;
    }
  }
}