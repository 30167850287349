.widget-2-collection {
  height: 100%;
  width: 100%;
  overflow: hidden;
  position: relative;
  background-color: $light_grey;

  .content {
    position: absolute;
    top: 0;
    left: 0;
    display: flex;
    flex-direction: column;
    color: $blue;
    height: 100%;
    width: 100%;
    padding: 3.2rem;
    align-items: flex-start;
    justify-content: flex-end;
  }

  .pretitle {
    margin-bottom: 0.4rem;
    line-height: 1;
  }

  .title {
    font-size: 3.2rem;
    font-weight: 300;
    line-height: 1.1;
    margin-bottom: 2.4rem;
  }

  a.button {
    border: 0;
    width: auto;

    p {
      margin-left: 0.4rem;
    }
  }

  .cta {
    position: relative;
    display: flex;
    font-size: 1.8rem;
    margin-top: 2.4em;
    text-decoration: underline;
    color: $blue;
  }

  &.margin-l {
    margin-bottom: 12rem;
  }

  .widget-2-bg {
    img {
      width: 100%;
      height: 100%;
      object-fit: cover;
    }
  }
}