.widget-k-promo-category {
  padding-left: 0;
  padding-right: 0;

  .wrapper {
    padding-left: 0;
    padding-right: 0;
  }
  &.add-margins {
    margin: 2rem 0;
  }
  .title,
  .subtitle {
    width: 80%;
    margin: 0 auto;
  }
  .subtitle {
    margin-top: 1rem;
  }
  .content-items {
    padding: 0 1.6rem;
  }
}
