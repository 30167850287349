.widget-k-promo-category {
  padding-top: 4rem;
  padding-bottom: 4rem;
  padding-left: 0;
  padding-right: 0;

  &.add-margins {
    margin: 4rem 0;
  }

  &.bg-white {
    .link {
      &::before {
        border-top: 0.1rem solid $black;
      }
    }
  }

  &.bg-red {
    background-color: $red;
    color: $white;

    .tag-item {
      border: 0.1rem solid $white;
    }
  }

  &.bg-black {
    background-color: $black;
    color: $white;

    .tag-item {
      border: 0.1rem solid $white;
    }
  }

  .wrapper {
    margin: 0 auto;
    padding-left: 0;
    padding-right: 0;
    text-align: center;
  }

  .title {
    font-size: 3.2rem;
    font-weight: 500;
    line-height: 1.13;

    margin: 0 auto;
    width: 90%;
  }

  .subtitle {
    font-size: 1.6rem;
    line-height: 1.5;

    margin: 0 auto;
    width: 90%;

    margin-top: 1rem;
  }

  .link {

    text-decoration: none;
    margin-top: 2.4rem;
    display: block;
    width: fit-content;
    margin-left: auto;
    margin-right: auto;

    &::before {
      border-top: 0.1rem solid $white;
    }
  }

  .content-items {
    margin-top: 3rem;
    padding: 0 2.4rem;
    display: flex;
    overflow: auto;

    &::-webkit-scrollbar {
      display: none;
    }
  }
}

.tag-item {
  width: max-content;
  border: 0.2rem solid $medium_grey;
  border-radius: 0.4rem;
  color: $dark_grey;
  padding: 0.8rem 1.2rem;

  &:not(:last-child) {
    margin-right: 1rem;
  }

  &:first-child {
    margin-left: auto;
  }

  &:last-child {
    margin-right: auto;
  }

  a {
    text-decoration: none;
    display: inline-block;
  }

  p {
    vertical-align: middle;
    width: inherit;
    display: inline-block;

    &.no-link {
      padding: 0.8rem 1.6rem;
    }
  }

  &:disabled {
    opacity: 0.3;
    cursor: none;
    pointer-events: none;

    &:after,
    &:before {
      opacity: 0.3;
    }
  }

  &:after {
    vertical-align: text-top;
  }
}