.widget-b-editorial {
  padding: 2.4rem;
  flex-direction: column;
  .title {
    font-size: 3.2rem;
    margin-bottom: 2.4rem;
  }
  .text-wrapper {
    padding-right: 0;
    padding-left: 0;
  }
  .img-wrapper {
    padding: 0;
  }
  .paragraph {
    margin-top: 2.4rem;
  }
}
