.widget-j-o2o {
  margin: 2rem 0;

  .content-items {
    position: relative;
    display: grid;
    grid-template-columns: 1fr 1fr;
    grid-template-rows: 1fr 1fr;
    padding: 0;

    &::before {
      content: '';
      width: 0.1rem;
      height: 100%;
      background-color: $grey;
      position: absolute;
      top: 0;
      left: 50%;

      transform: translateX(-50%);
    }

    &::after {
      content: '';
      width: 100%;
      height: 0.1rem;
      background-color: $grey;
      position: absolute;
      top: 50%;
      left: 0;

      transform: translateY(-50%);
    }
  }

  .box-item {
    padding: 4rem 1.5rem;
  }
}