.widget-j-o2o {
  margin: 4rem 0;

  .content-items {
    display: grid;
    grid-template-columns: 1fr 1fr 1fr 1fr;
    // background-color: $light_grey;
    padding: 6rem 0;
  }

  .box-item {
    display: flex;
    flex-direction: column;
    align-items: center;
    text-align: center;
    max-width: 26rem;
    margin: 0 auto;
    padding: 1rem;

    img {
      width: 2.4rem;
      height: 2.4rem;
      margin: 0 auto;
    }

    a {
      text-decoration: none;
    }
  }

  .box-title {
    font-size: 1.8rem;
    line-height: 1.22;

    margin-top: 1.6rem;
  }

  .box-subtitle {
    font-size: 1.4rem;
    line-height: 1.57;

    color: $dark_grey;
    margin-top: 1rem;
  }
}