.widget-a {
  height: 32rem;
  width: 100%;
  overflow: hidden;
  position: relative;
  background-color: #0C1C3E;

  &-content {
    position: absolute;
    top: 0;
    left: 0;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    color: $white;
    height: 32rem;
    width: 100%;
    padding: 1rem 4rem;
    gap: 2.4rem;
  }

  &-title {
    font-size: 3.2rem;
    font-weight: 400;
    line-height: 1.1;
    text-align: center;
  }

  &-subtitle {
    font-size: 1.4rem;
    font-weight: 300;
    line-height: 2.4rem;
    text-align: center;
  }

  &-cta {
    position: relative;
    display: flex;
    font-size: 1.4rem;
    text-decoration: underline;
    color: $white;
  }

  &.margin-l {
    margin-bottom: 12rem;
  }

  &-bg {
    width: 100%;
    height: 32rem;

    img {
      width: 100%;
      height: 32rem;
      object-fit: cover;
      object-position: center;
    }
  }

  &-logo {
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%,-50%);
  }
}