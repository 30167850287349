.widget-3-promo {
  height: calc((((100vw - (4rem * 3) - 2rem) / 3)) / (900 / 1200));
  justify-content: space-between;
  position: relative;
  &.color- {
    &white {
      color: $white;
    }
    &blue {
      color: $blue;
    }
  }
  
  .content {
    padding: 3.2rem;
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
  }
  .text-wrapper {
    display: flex;
    flex-direction: column;
  }

  .pretitle {
    margin-bottom: 1.6rem;
    line-height: 1;
  }

  .title {
    font-size: 4rem;
    font-weight: 400;
    line-height: 1.2;
  }

  .cta {
    position: relative;
    display: flex;
    font-size: 1.8rem;
    margin-top: 2.4em;
    text-decoration: underline;
    color: $white;
  }

  .promo-code {
    position: relative;
    text-decoration: none;
    display: inline-flex;
    align-items: center;
    justify-content: center;
    margin: 0;
    height: 4.8rem;
    width: 100%;
    white-space: nowrap;
    -webkit-user-select: none;
    -moz-user-select: none;
    -ms-user-select: none;
    user-select: none;
    padding: 0 3.2rem;
    text-anchor: middle;
    &.color {
      &-white {
        border: $space-line solid $white;
      }
      &-blue {
        border: $space-line solid $blue;
      }
    }
    text-transform: uppercase;
  }

  .bottom {
    position: absolute;
    bottom: 3.2rem;
    width: calc(100% - 6.4rem);
  }
  
  .widget-3-bg {
    img {
      width: 100%;
      height: 100%;
      object-fit: cover;
    }
  }
}


.experience-region,
.experience-component {

  :has(.widget-3-promo),
  .widget-3-promo {
    width: 100%;
  }
}