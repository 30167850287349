.widget-1-collection {
  height: 100%;
  width: 100%;
  overflow: hidden;
  position: relative;

  .content {
    position: absolute;
    top: 0;
    left: 0;
    display: flex;
    flex-direction: column;
    color: $white;
    height: 100%;
    width: 100%;
    padding: 3.2rem;
    align-items: flex-start;
    justify-content: flex-end;
  }

  .title {
    font-size: 3.2rem;
    font-weight: 300;
    line-height: 1.1;
  }

  .flex-row {
    margin-top: 2rem;
  }

  .cta {
    position: relative;
    display: flex;
    font-size: 1.8rem;
    color: $white;
    margin-right: 0.8rem;
  }

  &.margin-l {
    margin-bottom: 12rem;
  }

  .widget-1-bg {
    img {
      width: 100%;
      height: 100%;
      object-fit: cover;
    }
  }
}