.widget-2-editorial {
  height: 32rem;
  width: 100%;
  overflow: hidden;
  position: relative;
  margin-bottom: 4rem;
  color: $white;

  .widget-2-content {
    position: absolute;
    top: 0;
    left: 0;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    color: $white;
    height: 32rem;
    width: 100%;
    padding: 1rem 4rem;
  }

  .widget-2-title {
    font-size: 3.2rem;
    font-weight: 300;
    line-height: 1.1;
    text-align: center;
  }

  .widget-2-editorial-cta {
    position: relative;
    display: flex;
    font-size: 1.8rem;
    margin-top: 2.4em;
    text-decoration: underline;
  }
}